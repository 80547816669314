.MuiDialog-paper {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}
#drop_zone_wrapper {
  position: relative;
  width: 100%;
  height: 200px;
}
#drop_zone {
  /* border: 2px dashed #283593; */
  margin-left: 1%;
  margin-bottom: 2%;
  width: 98%;
  height: 86%;
  border-radius: 15px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center 50%;
  background-size: 35% 40%;
  z-index: -1;
}
.example-btns {
  color: #283593;
  padding-left: 0;
  height: 25px;
}
.begin-button {
  width: 200px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  line-height: 23px;
}
.choose-statement {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #848484;
  margin-bottom: 70px;
}
.choose-button {
  background-color: white;
  height: 20vw;
  width: 20vw;
  background-repeat: no-repeat;
  background-position: center 40%;
  background-size: 35% 35%;
  border-radius: 8px;
  border-color: #848484;
  position: absolute;
}
.choose-button:active {
  background-color: white;
}
.button-text {
  position: relative;
  top: 6vw;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
  /* line-height: 41px; */
}
.button-desc {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  line-height: 130%;
  position: relative;
}
.browse-header {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
}
.browseP {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #848484;
}
.browse-buttonsH {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  /* line-height: 28px; */
  position: relative;
  bottom: 27px;
  padding-left: 35px;
  text-align: left;
}
.browse-buttonsT {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  bottom: 10px;
  padding-left: 15px;
  text-align: left;
}
.browse-button {
  display: inline-block;
  margin: 30px;
  width: 290px;
  height: 155px;
  left: 735px;
  top: 339px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(66, 56, 124, 0.25);
  border-radius: 15px;
  /* padding-left: 50px; */
  background-position: 15px 15px;
  background-size: 20px 25px;
  background-repeat: no-repeat;
}
.browse-button:active {
  background-color: white;
}
.dataset-website {
  display: inline-table;
  padding: 40px;
}
.data-link {
  color: #0085ff;
}
.new-dropBox {
  background-color: white;
  height: 26vw;
  width: 26vw;
  background-repeat: no-repeat;
  background-position: center 40%;
  background-size: 35% 35%;
  border-radius: 8px;
  border: 2px #848484 solid;
  position: absolute;
  left: 37vw;
  /* border-color: #848484; */
}
.dropzone-text {
  position: absolute;
  top: 18vw;
  right: 3.5vw;
  font-size: 1.6vw;
}
