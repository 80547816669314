h1,
h2,
h4,
h6 {
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  /* font-family: "Roboto", sans-serif; */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  /* letter-spacing: 0.12rem; */
}
.pres {
  font-size: 1.2rem;
}
.pd_text1 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  color: #ffffff;
}
.pd_text2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height */

  /* display: flex; */
  /* align-items: center; */

  color: #ffffff;
}
.pd_text3 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-transform: none;
  /* identical to box height */

  align-items: center;
  text-align: center;
}
.pd_text4 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-transform: none;
}
.content-sum {
  height: 150px;
}
.purple-text {
  color: #283593 !important;
}
.pink-text {
  color: #f8bbd0 !important;
}
.yellow-warning-text {
  color: #ffcc00 !important;
}
.btn-floating {
  background-color: #283593;
  display: inline-block !important;
}
.btn {
  font-weight: bold;
  background-color: #283593;
  border-color: #283593;
  border-radius: 30px;
/*   text-align: center; */
  display: table-cell;
  vertical-align: middle;
}
.btn:focus {
  background-color: #283593;
}
.btn:hover {
  background-color: #6e689be0;
  border-color: #6e689be0;
}
.btn-outline {
  color: #283593;
  border-color: #283593;
  border-width: 1.5px;
  border-style: solid;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
}
.btn-outline-gen {
  color: #757575;
  border-color: #757575;
  border-width: 1.5px;
  border-style: solid;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
}
.btn-outline-del {
  color: #ff4545;
  border-color: #ff4545;
  border-width: 1.5px;
  border-style: solid;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
}
.btn-outline-edit {
  color: #ffffff;
  border-color: #ffffff;
  border-width: 1.5px;
  border-style: solid;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
}
.btn-outline:focus {
  background-color: transparent;
}
.btn-outline:hover {
  background-color: #9e98d12a;
}
.btn-sec {
  background-color: #f8bbd0;
  border-color: #f8bbd0;
  color: black;
}
.btn-sec:focus {
  background-color: #f8bbd0;
}
.btn-sec:hover {
  background-color: #ffdce8ed;
}
.btn-large {
  border-radius: 30px;
  background-color: #283593;
}
.btn-large:focus {
  background-color: #283593;
}
.btn-large:hover {
  background-color: #6e689be0;
}
.project-summary {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.project-picture {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  background-position: center;
  color: white;
  height: 250px;
}
.nav-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 0 0;
  z-index: 5;
}
.right a:hover,
.right button:hover {
  background: #db91b67e;
}
#nav-cta:hover {
  background: none !important;
}
.hearts {
  color: #f8bbd0;
}
a {
  color: #283593;
}
.MuiCircularProgress-colorPrimary {
  color: #f8bbd0 !important;
}
.MuiSvgIcon-root {
  color: none;
}
.disabled {
  color: #aaaaaa !important;
}
.MuiSlider-root {
  color: #283593 !important;
}
.MuiMenuItem-root {
  font-size: 1.1rem !important;
}
.MuiSelect-root {
  font-size: 1.5rem !important;
  color: #283593 !important;
  font-weight: 600 !important;
}
.slider-title {
  padding-top: 2rem;
}
.slider-row {
  background-color: #e9e9e9;
}
.slider-contain {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  min-height: 10rem;
}
.cat-drop-down {
  position: relative;
  float: right;
}
