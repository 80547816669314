.img-row {
  width: 100%;
  overflow: "hidden";
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
}
.white-background-landing {
  background-color: #fefefe;
}
.authImg {
  width: 100%;
}
.land-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.land-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.headshot-img {
  height: calc(8rem + 9vh + 1vw);
  width: calc(8rem + 9vh + 1vw);
  border-radius: 100%;
}
.headshot-img-wrapper {
  padding: 5px;
}
.anchor {
  width: 200px;
}
.anchor-160 {
  width: 160px;
  border-width: 2px;
  border-style: solid;
}
.header-subrow {
  padding: 10px;
}
.goals {
  padding-top: calc(10vh + 5vw + 1rem);
  padding-bottom: calc(18vh + 10vw + 12rem);
}
@media only screen and (max-width: 565px) {
  .row {
    background-image: none !important;
  }
  .goals {
    padding-bottom: calc(30vh + 50vw);
  }
  .anchor-link {
    display: none;
  }
  .brand-logo {
    left: 19% !important;
  }
}
