.img-row {
	width: 100%;
	overflow: "hidden";
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #eeeeee;
	padding-bottom: 0px;
}
.infoText {
	font-size: 24px;
	line-height: 130%;
}
.purpleHeader {
	line-height: 130%;
	font-size: 36px
}
.ap-header {
	height: calc(15rem + 8vh);
}
.abt {
	padding-top: 15px;
}
.ap-line {
	text-align: center;
	width: calc(20rem + 60vw);
	font-weight: 800;
}
.aplearn {
  padding-bottom: calc(4rem + 5vh);
}
.p-signup {
  font-weight: 500;
  font-size: 125%;
}
.wwa {
  font-weight: bold;
}
.SM {
  margin: 10px; 
}
.OG {
	padding-top: calc(2rem + 1vh);
	font-weight: bold;
}
.GIT {
	padding-top: calc(2rem + 5vh);
	font-weight: bold;
}
.apland {
	padding-bottom: calc(5rem + 6vh);
}
.headShot {
	margin-left: calc(3rem + 6vw);
	margin-right: calc(3rem + 6vw);
}