.title-dash {
  position: relative;
  width: 798px;
  height: 74px;
  /* left: 99px;
  top: 136px; */

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 74px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #283593;
}
.subtitle-dash {
  position: relative;
  width: 324px;
  height: 34px;
  /* left: 99px;
  top: 224px; */

  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
  margin-top: 15px;

  color: #757575;
}
.pagination-icon {
  transform: scale(3);
  transition: transform 100ms;
}
.pagination-icon:hover {
  transform: scale(3.5);
  color: #f8bbd0;
}
.pagination-select {
  height: 2rem;
}
/* bar color */
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #f8bbd0 !important;
}
/* background pop color */
.switch label input[type="checkbox"]:checked + .lever:before {
  background-color: #a18ef6a6 !important;
}
/* lever or dot color */
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #283593 !important;
}
/* same but not checked*/
.switch label input[type="checkbox"] + .lever {
  background-color: #f8bbd0 !important;
}
.switch label input[type="checkbox"] + .lever:before {
  background-color: #a18ef6a6 !important;
}
.switch label input[type="checkbox"] + .lever:after {
  background-color: #283593 !important;
}
